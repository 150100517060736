/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Section from "../components/Section";
import Footer from "../components/Footer";
import DownloadEbookModal from "../components/DownloadEbookModal";
import FooterLinks from "../components/FooterLinks";
import { SectionEnum } from "../types/enums";
import Container from "../components/Container";
import AppContext from "../context/AppContext";
import useBreakpoint from "../hooks/useBreakpoint";
import CtaHero from "../components/CtaHero";

import "./style.scss";

const Home: React.FC = () => {
    const { isMobile, isTablet } = useBreakpoint();
    const [isModalOpen, setIsModalOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const appContent = useContext<any>(AppContext);

    const heroContent = appContent?.contents?.filter((el: any) => {
        return el?.fields?.section === "hero";
    });
    const media = heroContent?.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (el: any) => el?.fields?.key === "hero_background"
    )?.fields;
    const backgroundMedia =
        isMobile || isTablet
            ? media?.backgroundImageMobile?.fields?.file?.url
            : media?.backgroundImage?.fields?.file?.url;

    return (
        <>
            <main className="home">
                {/* Navbar & Hero */}
                <Container
                    className="navbar-hero-container"
                    fluid
                    style={{
                        backgroundImage: `url(${backgroundMedia})`,
                    }}
                >
                    <Navbar />
                    <Hero />
                    <Container className="cta-ebook-container" fluid>
                        <CtaHero setIsModalOpen={setIsModalOpen} />
                    </Container>
                </Container>

                {/* Section Info */}
                <Section type={SectionEnum.SECTION_ONE} />

                {/* CTA Section */}
                <Section
                    type={SectionEnum.SECTION_TWO}
                    setIsModalOpen={setIsModalOpen}
                />
                {/* Footer */}
                <Footer />
                {/* Form info modal */}
                <FooterLinks />
            </main>

            <DownloadEbookModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
        </>
    );
};

export default Home;
