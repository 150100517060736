/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react";
import Container from "../Container";
import { Checkbox, Input, Row, Form, Col } from "antd";
import Button from "../Button";
import { TypedRequest } from "../../types/interfaces";
import axios from "../../utils/axios";
import Card from "../Card";
import AppContext from "../../context/AppContext";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import Svg from "../Svg";

import "./style.scss";

type FieldType = {
    firstname: string;
    lastname: string;
    email: string;
    company: string;
    phone?: string;
    privacy: boolean;
};

interface FormDownloadProps {
    asCard?: boolean;
    setIsFormSentSuccess?: (value: boolean) => void;
}

const skipMailUp = parseInt(import.meta.env.VITE_REGISTRATION_SKIP_MAILUP);

const FormDownloadEbook: React.FC<FormDownloadProps> = ({ asCard = true }) => {
    const appContent = useContext<any>(AppContext);
    const [isFormSentSuccess, setIsFormSentSuccess] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [error, setError] = useState<any>();

    const onFinish = async ({
        firstname,
        lastname,
        email,
        company,
        privacy,
    }: TypedRequest) => {
        setIsSubmitLoading(true);
        try {
            const response = await axios.post(
                import.meta.env.VITE_REGISTRATION_POST_URL,
                {
                    firstname,
                    lastname,
                    email,
                    company,
                    privacy,
                    skipSubscription: skipMailUp,
                }
            );

            if (response.status === 200) {
                // Push event to Tag Manager
                window.dataLayer.push({
                    event:
                        import.meta.env.VITE_NEW_CALLBACK_REQUEST ||
                        "subscribed",
                });
                setIsFormSentSuccess(true);
            }
        } catch (error) {
            console.log("ERROR: ", error);
            setError(error);
        }
        setIsSubmitLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    const formContent = appContent?.contents?.filter((el: any) => {
        return el?.fields?.section === "form";
    });

    const mainTitle = formContent?.find(
        (el: any) => el?.fields?.key === "form_title"
    )?.fields?.contents;
    const subTitle = formContent?.find(
        (el: any) => el?.fields?.key === "form_subtitle"
    )?.fields?.contents;
    const ctaText = formContent?.find(
        (el: any) => el?.fields?.key === "form_cta_button_text"
    )?.fields?.contents;
    const thankYouTitle = formContent?.find(
        (el: any) => el?.fields?.key === "form_thankyou_title"
    )?.fields?.contents;
    const thankYouSubTitle = formContent?.find(
        (el: any) => el?.fields?.key === "form_thankyou_paragraph"
    )?.fields?.contents;
    const socialsText = formContent?.find(
        (el: any) => el?.fields?.key === "form_thankyou_social_text"
    )?.fields?.contents;

    const titles = [
        <Col
            key={"title"}
            className="form-contents-titles__title"
            dangerouslySetInnerHTML={{
                __html: documentToHtmlString(mainTitle),
            }}
        />,
        <Col
            key={"subtitle"}
            className="form-contents-titles__subtitle"
            dangerouslySetInnerHTML={{
                __html: documentToHtmlString(subTitle),
            }}
        />,
    ].filter((element) => element !== null);

    const form = (
        <div className="download-ebook__box">
            {!isFormSentSuccess ? (
                <>
                    <Row className="download-ebook__text-box" gutter={[0, 16]}>
                        {titles?.map((singleTitle, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {singleTitle}
                                </React.Fragment>
                            );
                        })}
                    </Row>

                    <Form
                        className="download-ebook__form"
                        name={`basic-form${"-" + asCard}`}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item<FieldType>
                            name="firstname"
                            rules={[
                                {
                                    required: true,
                                    message: "Inserisci il nome",
                                    max: 100,
                                },
                            ]}
                            normalize={(value) => value.trim()}
                        >
                            <Input
                                placeholder="Nome"
                                type="text"
                                autoComplete="true"
                            />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="lastname"
                            rules={[
                                {
                                    required: true,
                                    message: "Inserisci il cognome",
                                },
                            ]}
                            normalize={(value) => value.trim()}
                        >
                            <Input
                                placeholder="Cognome"
                                type="text"
                                autoComplete="true"
                            />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="company"
                            rules={[
                                {
                                    required: true,
                                    message: "Inserisci l'azienda",
                                },
                            ]}
                        >
                            <Input placeholder="Azienda" type="text" />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Inserisci l'email",
                                    min: 3,
                                },
                            ]}
                        >
                            <Input
                                placeholder="Email"
                                type="email"
                                autoComplete="true"
                            />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="privacy"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                    message: "Accetta la privacy",
                                },
                            ]}
                        >
                            <Checkbox className="download-ebook__checkbox">
                                Accetto la{" "}
                                <a
                                    href={`https://www.iubenda.com/privacy-policy/${
                                        import.meta.env.VITE_IUBENDA_ID
                                    }`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    privacy policy{" "}
                                </a>
                            </Checkbox>
                        </Form.Item>

                        {error && (
                            <Form.Item className="download-ebook__error">
                                Qualcosa è andato storto, per favore riprova più
                                tardi. Error {error.response.status}
                            </Form.Item>
                        )}

                        <Form.Item>
                            <Button
                                className="download-ebook__submit-button"
                                block
                                type="primary"
                                htmlType="submit"
                                loading={isSubmitLoading}
                            >
                                <span
                                    key={"ctaText"}
                                    dangerouslySetInnerHTML={{
                                        __html: documentToHtmlString(ctaText),
                                    }}
                                />
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            ) : (
                <Row className="download-ebook__success" gutter={[0, 40]}>
                    <Col span={24} className="download-ebook__thankyou">
                        <span
                            key={"thankYouTitle"}
                            className="download-ebook__thankyou-title"
                            dangerouslySetInnerHTML={{
                                __html: documentToHtmlString(thankYouTitle),
                            }}
                        />
                        <span
                            key={"thankYouSubTitle"}
                            className="download-ebook__thankyou-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: documentToHtmlString(thankYouSubTitle),
                            }}
                        />
                    </Col>
                    <Col span={24} className="download-ebook__socials">
                        <span
                            key={"socialsText"}
                            className="download-ebook__socialsText"
                            dangerouslySetInnerHTML={{
                                __html: documentToHtmlString(socialsText),
                            }}
                        />
                        <span className="download-ebook__socials-box">
                            <Svg
                                socialName={"linkedin"}
                                asLink="https://www.linkedin.com/company/adm-media-consulting/"
                            />
                            <Svg
                                socialName={"instagram"}
                                asLink="https://www.instagram.com/adm_mediabrandsolutions/"
                            />
                        </span>
                    </Col>
                </Row>
            )}
        </div>
    );

    useEffect(() => {
        return () => {
            setIsSubmitLoading(false);
        };
    }, []);

    return asCard ? (
        <Card className="download-ebook-card">{form}</Card>
    ) : (
        <Container className="download-ebook-wrapper" padding={{ x: 1 }}>
            {form}
        </Container>
    );
};

export default FormDownloadEbook;
