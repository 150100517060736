/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Col, Row } from "antd";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import useBreakpoint from "../../hooks/useBreakpoint";
import AppContext from "../../context/AppContext";
import FormDownloadEbook from "../FormDownload";
import Container from "../Container";

import "./style.scss";

interface IHero {
    setIsModalOpen?: (value: boolean) => void;
}

const defaultInitialTimeoutValue = 200;
const defaultAnimationTimeoutValue = 400;
const heroAnimationInitialTimeout = 300;
const heroAnimationTimeout = 300;

const Hero: React.FC<IHero> = () => {
    const { windowSize } = useBreakpoint();
    const [isExecutable, setIsExecutable] = useState(false);
    const [visibilityArray, setVisibilityArray] = useState<boolean[]>([]);

    const appContent = useContext<any>(AppContext);

    const heroContent = appContent?.contents?.filter((el: any) => {
        return el?.fields?.section === "hero";
    });

    const mainTitle = heroContent?.find(
        (el: any) => el?.fields?.key === "hero_title"
    )?.fields?.contents;
    const secondTitle = heroContent?.find(
        (el: any) => el?.fields?.key === "hero_subtitle"
    )?.fields?.contents;
    const descriptionCompany = heroContent?.find(
        (el: any) => el?.fields?.key === "hero_paragraph"
    )?.fields?.contents;
    const media = heroContent?.find(
        (el: any) => el?.fields?.key === "hero_background"
    )?.fields;

    const withAnimation = true;

    const titles = [
        <Col
            key={"mainTitle"}
            className="hero-contents-titles__title"
            dangerouslySetInnerHTML={{
                __html: documentToHtmlString(mainTitle),
            }}
        />,
        <Col
            key={"secondTitle"}
            className="hero-contents-titles__subtitle"
            dangerouslySetInnerHTML={{
                __html: documentToHtmlString(secondTitle),
            }}
        />,
        <Col
            xs={24}
            sm={24}
            md={20}
            lg={16}
            xl={16}
            xxl={16}
            className="hero-contents-titles__description-company"
            key={"descriptionCompany"}
            dangerouslySetInnerHTML={{
                __html: documentToHtmlString(descriptionCompany),
            }}
        />,
    ].filter((element) => element !== null);

    const setVisibilityNext = () => {
        setVisibilityArray((visibilityArray) => {
            const a = [...visibilityArray];
            a.push(true);
            return a;
        });
    };

    useEffect(() => {
        if (!heroContent || heroContent.length === 0) return;
        const initialTimeout =
            Number(heroAnimationInitialTimeout) ?? defaultInitialTimeoutValue;

        const timeout = setTimeout(() => {
            setVisibilityNext();
        }, initialTimeout);

        // Clean timeout
        if (isExecutable) {
            clearTimeout(timeout);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [withAnimation, heroContent, isExecutable]);

    return (
        <Suspense fallback={""}>
            <Container className="hero-wrapper" fluid>
                <div
                    className={classNames("hero-media-container", {
                        withAnimation,
                        isVideo:
                            windowSize.width &&
                            media?.backgroundVideo &&
                            !media?.backgroundImage,
                        isImage: windowSize.width && !media?.backgroundVideo,
                        isExecutable,
                        noAnimation:
                            !withAnimation ||
                            (Number(heroAnimationInitialTimeout) === 0 &&
                                Number(heroAnimationTimeout) === 0),
                    })}
                    // style={{
                    //     backgroundImage: `url(${backgroundMedia})`,
                    // }}
                ></div>

                <Container className={classNames("hero-contents-box")}>
                    <Row
                        className={classNames("hero-contents")}
                        gutter={[
                            { xxl: 0, xl: 0, lg: 0, md: 0, sm: 0, xs: 0 },
                            { md: 0, sm: 0, xs: 0 },
                        ]}
                    >
                        <Col
                            xxl={16}
                            xl={15}
                            lg={15}
                            md={24}
                            sm={24}
                            xs={24}
                            className={classNames("hero-contents-titles", {
                                withAnimation,
                            })}
                        >
                            {titles?.map((singleTitle, index) => {
                                if (withAnimation) {
                                    let currentIndex = visibilityArray[index];
                                    if (!currentIndex) currentIndex = false;

                                    return (
                                        <React.Fragment key={index}>
                                            <CSSTransition
                                                data-testid="hero-title-transition"
                                                classNames="hero-title-transition"
                                                in={currentIndex}
                                                timeout={
                                                    Number(
                                                        heroAnimationTimeout
                                                    ) ??
                                                    defaultAnimationTimeoutValue
                                                }
                                                onEntered={() => {
                                                    setVisibilityNext();
                                                    // Execute video, wait for last animation to be launched
                                                    if (
                                                        withAnimation &&
                                                        index ===
                                                            titles.length - 1
                                                    ) {
                                                        setIsExecutable(true);
                                                    }
                                                }}
                                            >
                                                {singleTitle}
                                            </CSSTransition>
                                        </React.Fragment>
                                    );
                                }
                                return (
                                    <React.Fragment key={index}>
                                        {singleTitle}
                                    </React.Fragment>
                                );
                            })}
                        </Col>
                        <Col
                            xxl={8}
                            xl={9}
                            lg={9}
                            md={0}
                            sm={0}
                            xs={0}
                            className="hero-contents-form"
                        >
                            <FormDownloadEbook />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Suspense>
    );
};

export default Hero;
