import { Col, Row } from "antd";
import Container from "../Container";
import footerLogo from "../../assets/adm-black-logo.png";
import Svg from "../Svg";

import "./style.scss";

export interface FooterProps {
    copyrightText?: string;
    details?: string;
    alignContent?: "start" | "end" | "center";
}

export interface IOffice {
    id: number;
    abbr: string;
    location: string;
    address: string;
    cap: string;
    phone: string;
}

const offices: IOffice[] = [
    {
        id: 1,
        abbr: "CH",
        location: "SWITZERLAND | HQ",
        address: "Via Giuseppe Corti, n.5",
        cap: "6828 Balerna",
        phone: "+41 91 252 0243",
    },
    {
        id: 2,
        abbr: "NY",
        location: "NEW YORK | U.S. BRANCH",
        address: "99 Madison Ave, Suite 5026",
        cap: "New York, NY 10016",
        phone: "+1 332 238 4673",
    },
    {
        id: 3,
        abbr: "HK",
        location: "HONG KONG | REP. OFFICE",
        address:
            "Block H, 12/F, Phase 4 Kwun Tong Ind. Centre 436-446 Kwun Tong Road",
        cap: "Kowloon, HKSAR",
        phone: "+852 2770 2370",
    },
];

const officeBlock = ({ id, abbr, location, address, cap, phone }: IOffice) => {
    return (
        <div key={id} className="footer__office-block">
            <h2>{abbr}</h2>
            <span className="footer__office-block__box-info">
                {location}
                <span>
                    <p>
                        {address} {cap}
                    </p>
                </span>

                <a href={`tel:${phone}`}>{phone}</a>
            </span>
        </div>
    );
};

const Footer: React.FC<FooterProps> = () => {
    return (
        <Container padding={{ y: 4 }} className="footer" customTag="footer">
            <Row gutter={[24, 24]}>
                <Col
                    className="footer__company-info"
                    xs={24}
                    sm={24}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                >
                    <span className="footer__company-info__logo">
                        <img src={footerLogo} alt="ADM Media brand solutions" />
                    </span>
                    <span className="footer__company-info__section-word">
                        BECOME A CLIENT.
                    </span>
                    <span className="footer__company-info__website">
                        <a
                            type="email"
                            href="https://adm-mediaconsulting.com"
                            target="_blank"
                        >
                            adm-mediaconsulting.com
                        </a>
                    </span>
                    <span className="footer__company-info__email">
                        <a
                            type="email"
                            href="mailto:info@adm-mediaconsulting.com"
                        >
                            info@adm-mediaconsulting.com
                        </a>
                    </span>
                    <span className="footer__company-info__socials">
                        <Svg
                            socialName={"linkedin"}
                            asLink="https://linkedin.com/company/adm-media-consulting/"
                        />
                        <Svg
                            socialName={"instagram"}
                            asLink="https://instagram.com/adm_mediabrandsolutions/"
                        />
                    </span>
                </Col>
                {offices.map((item, index) => (
                    <Col
                        className={`footer__office${index}`}
                        key={index}
                        xs={24}
                        sm={24}
                        md={6}
                        lg={6}
                        xl={6}
                        xxl={6}
                    >
                        {officeBlock(item)}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Footer;
