import axios from "axios";

/** New instance of axios created for passing basic configuration */
const axiosInstance = axios.create({
    headers: {
        "X-Requested-With": "XMLHttpRequest",
    },
});

export default axiosInstance;
