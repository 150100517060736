/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from "react";
import Container from "../Container";
import classNames from "classnames";
import CTAButton from "../CTAButton";
import { Mode, SectionEnum } from "../../types/enums";
import AppContext from "../../context/AppContext";
import { doesContain } from "../../utils/doesContain";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import "./style.scss";

interface SectionProps {
    className?: string;
    withCTA?: boolean;
    setIsModalOpen?: (value: boolean) => void;
    type?: SectionEnum;
    customPadding?: { y: number; x: number };
}

const Section: React.FC<SectionProps> = ({
    className,
    setIsModalOpen,
    type,
}) => {
    const appContent = useContext<any>(AppContext);
    const [contents, setContents] = useState<any>();
    const [ctaText, setCtaText] = useState<any>();
    const [redirectText, setRedirectText] = useState<any>();
    const [mediaImage, setMediaImage] = useState<{
        description: string;
        file: { contentType: string; fileName: string; url: string };
        title: string;
    }>();

    const populateText = (
        contents: {
            fields: {
                key: string;
                contents: { content: any[] };
                image: any;
            };
        }[]
    ) => {
        contents?.forEach((element) => {
            if (doesContain(element?.fields?.key, "cta button")) {
                setCtaText(element?.fields?.contents);
            } else if (doesContain(element?.fields?.key, "section")) {
                setContents(element?.fields?.contents);
            } else if (doesContain(element?.fields?.key, "cta redirect link")) {
                setRedirectText(element?.fields?.contents);
            }

            if (element?.fields?.image) {
                setMediaImage(element?.fields?.image?.fields);
            }
        });
    };

    const getContentBasedOnType = () => {
        let contents;
        switch (type) {
            case SectionEnum.SECTION_ONE:
                contents = appContent?.contents?.filter((el: any) => {
                    return el?.fields?.section === SectionEnum.SECTION_ONE;
                });
                populateText(contents);
                break;

            case SectionEnum.SECTION_TWO:
                contents = appContent?.contents?.filter((el: any) => {
                    return el?.fields?.section === SectionEnum.SECTION_TWO;
                });
                populateText(contents);
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        if (!appContent) return;
        getContentBasedOnType();
    }, [appContent]);

    return (
        <Container
            className={classNames("section", className, type)}
            customTag="section"
        >
            <div className="section-wrapper">
                {mediaImage && (
                    <div className={classNames("section-wrapper__ebook-img")}>
                        <img
                            loading={"lazy"}
                            alt={mediaImage?.description}
                            src={mediaImage?.file.url}
                        />
                    </div>
                )}
                <span
                    className="section-wrapper__title"
                    dangerouslySetInnerHTML={{
                        __html: documentToHtmlString(contents),
                    }}
                />
                {ctaText && (
                    <CTAButton
                        mode={Mode.OPEN_FORM}
                        onClick={setIsModalOpen}
                        ctaText={ctaText}
                    />
                )}
                {redirectText && (
                    <span
                        className="section-wrapper__redirect"
                        dangerouslySetInnerHTML={{
                            __html: documentToHtmlString(redirectText),
                        }}
                    />
                )}
            </div>
        </Container>
    );
};

export default Section;
