/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigProvider, ThemeConfig } from "antd";
import Home from "./pages/Home";
import { Suspense, useEffect, useState } from "react";
import { getAppData } from "./lib/api/contentfulClient";
import AppContext from "./context/AppContext";
import TagManagerInit from "./components/TagManager/TagManager";

const theme: ThemeConfig = {
    token: {
        colorPrimary: "#4B326B",
    },
    components: {
        Button: {
            primaryColor: "#fff",
            primaryShadow: undefined,
            colorPrimary: "#F13E2E"
        },
    },
};

const App: React.FC = () => {
    const [appData, setAppData] = useState<any>();

    const getData = async () => {
        const data: {
            contents: any[];
        } = await getAppData();
        setAppData(data);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Suspense fallback="">
            <TagManagerInit />
            <ConfigProvider theme={theme}>
                <AppContext.Provider
                    value={{
                        contents: appData?.contents,
                    }}
                >
                    <Home />
                </AppContext.Provider>
            </ConfigProvider>
        </Suspense>
    );
};

export default App;
