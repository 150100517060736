/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from "antd";
import React, { useContext } from "react";
import classNames from "classnames";
import AppContext from "../../context/AppContext";
import { INLINES } from "@contentful/rich-text-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import "./style.scss";

const FooterLinks: React.FC = () => {
    let links;
    const appContent = useContext<any>(AppContext);

    const footerLinks = appContent?.contents?.filter((el: any) => {
        return el?.fields?.section === "footer_links";
    });

    if (footerLinks) {
        links = [...footerLinks]?.reverse();
    }

    // This assign target prop to link
    // https://www.contentfulcommunity.com/t/add-a-target-blank-to-hyperlink-within-rich-text-content-type/2650
    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node: any, next: any) => {
                return `<a href="${node.data.uri}" target="_blank">${next(
                    node.content
                )}</a>`;
            },
        },
    };

    return (
        <Row justify={"space-around"} className="footer__links-container">
            {links?.map((link, index) => {
                return (
                    <Col
                        className="footer__link-box"
                        xs={24}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        key={index}
                    >
                        <span
                            className={classNames("footer__link", {
                                normalCursor: !link.href,
                            })}
                            dangerouslySetInnerHTML={{
                                __html: documentToHtmlString(
                                    link?.fields?.contents,
                                    options
                                ),
                            }}
                        />
                    </Col>
                );
            })}
        </Row>
    );
};

export default FooterLinks;
