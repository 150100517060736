/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import classNames from "classnames";
import { Affix } from "antd";
import Container from "../Container";
import AppContext from "../../context/AppContext";

import "./style.scss";
export interface NavbarProps {
    affix?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ affix = true }) => {
    const appContent = useContext<any>(AppContext);
    const [affixed, toggleAffixed] = useState<boolean>(false);

    const navbarLogo = appContent?.contents?.find(
        (el: any) => el?.fields?.key === "navbar_logo"
    )?.fields;

    const navbarClassNames = classNames("navbar", {
        "navbar--affixed": affixed && affix,
    });

    const navbar = (
        <Container customTag="nav" className={navbarClassNames}>
            <div className="navbar__logo-box">
                {navbarLogo?.backgroundImage?.fields?.file?.url && (
                    <img
                        className="navbar__logo"
                        src={navbarLogo?.backgroundImage?.fields?.file?.url}
                        alt={"adm-logo"}
                        loading="lazy"
                    />
                )}
            </div>
        </Container>
    );

    const handleChangeAffix = (affixed?: boolean) =>
        toggleAffixed(affixed || false);

    return affix ? (
        <Affix onChange={handleChangeAffix} className={classNames("affix")}>
            {navbar}
        </Affix>
    ) : (
        navbar
    );
};

export default Navbar;
