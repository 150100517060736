import { useEffect } from "react";
import TagManager from "react-gtm-module";

const TagManagerInit = () => {
    // Google
    useEffect(() => {
        if (!import.meta.env.VITE_GOOGLE_TGM_ID || import.meta.env.VITE_GOOGLE_TGM_ID === "-") return;
        const tagManagerArgs = {
            gtmId: import.meta.env.VITE_GOOGLE_TGM_ID,
        };
        TagManager.initialize(tagManagerArgs);
    }, []);

    // // Facebook
    // useEffect(() => {
    //     if (!import.meta.env.VITE_FACEBOOK_PIXEL) return;
    //     ReactPixel.init(import.meta.env.VITE_FACEBOOK_PIXEL);
    //     //  ReactPixel.revokeConsent();

    //     ReactPixel.pageView();
    // }, []);

    // // Linkedin
    // useEffect(() => {
    //     if (!import.meta.env.VITE_LINKEDIN_PIXEL) return;
    // });

    return <></>;
};

export default TagManagerInit;
