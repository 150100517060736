/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from "antd";
import CTAButton from "../CTAButton";
import { Mode } from "../../types/enums";
import { useContext } from "react";
import AppContext from "../../context/AppContext";

import "./style.scss";

interface CtaHeroProps {
    setIsModalOpen: (value: boolean) => void;
}

const CtaHero: React.FC<CtaHeroProps> = ({ setIsModalOpen }) => {
    const appContent = useContext<any>(AppContext);

    const heroContent = appContent?.contents?.filter((el: any) => {
        return el?.fields?.section === "hero";
    });

    const ctaText = heroContent?.find(
        (el: any) => el?.fields?.key === "cta button text hero"
    )?.fields?.contents;

    return (
        <Row className="hero-cta">
            <Col xxl={0} xl={0} lg={0} md={24} sm={24} xs={24}>
                <CTAButton
                    mode={Mode.OPEN_FORM}
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                    ctaText={ctaText}
                />
            </Col>
        </Row>
    );
};

export default CtaHero;
