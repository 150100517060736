export enum Mode {
    OPEN_FORM = "open-form",
}

export enum Countries {
    IT = "it",
    CH = "ch",
}

export enum SectionEnum {
    SECTION_ONE = "section1",
    SECTION_TWO = "section2",
    SECTION_THREE = "section3",
    SECTION_FOUR = "section4",
    SECTION_SLIDER = "sectionSlider",
}
