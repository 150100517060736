/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from "../Button";
import classNames from "classnames";
import { Mode } from "../../types/enums";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";

import "./style.scss";

interface CTAButtonProps {
    mode?: Mode;
    onClick: any;
    className?: string;
    block?: boolean;
    ctaText: any;
}

const CTAButton: React.FC<CTAButtonProps> = ({
    className,
    onClick,
    block,
    ctaText,
}) => {
    return (
        <>
            {ctaText && (
                <Button
                    className={classNames("cta-button", className)}
                    size={"large"}
                    aria-label={ctaText}
                    onClick={onClick}
                    block={block}
                    type={"primary"}
                    role="button"
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: documentToHtmlString(ctaText, {
                                renderNode: {
                                    [BLOCKS.PARAGRAPH]: (node, next) =>
                                        next(node.content).replace(
                                            "\n",
                                            "<br/>"
                                        ),
                                },
                            }),
                        }}
                    ></span>
                </Button>
            )}
        </>
    );
};
export default CTAButton;
