/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import "./style.scss";

export interface ModalProps extends AntModalProps {
    children: any;
    destroyModal?: boolean;
    className?: string;
    modalClassName?: string;
    modalBodyClassName?: string;
    modalName?: string;
}

const Modal: React.FC<ModalProps> = ({
    destroyModal = true,
    destroyOnClose = true,
    footer = null,
    children,
    ...props
}) => {
    return (
        <AntModal
            open={false}
            destroyOnClose={destroyModal ? destroyModal : destroyOnClose}
            footer={footer}
            className={props.modalClassName}
            centered={true}
            {...props}
        >
            <div data-testid="modal-body" className={props.modalBodyClassName}>
                {children}
            </div>
        </AntModal>
    );
};

export default Modal;
