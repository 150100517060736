import classNames from "classnames";
import Instagram from "../../assets/ig.svg?react";
import Linkedin from "../../assets/in.svg?react";

import "./style.scss";

type SocialNameType = "linkedin" | "instagram";

interface SvgProps {
    socialName: SocialNameType;
    size?: number;
    asLink?: string;
}

const Svg: React.FC<SvgProps> = ({ socialName, size = 1, asLink }) => {
    let social;

    switch (socialName) {
        case "linkedin":
            social = (
                <Linkedin
                    className={classNames(
                        "svg-component linkedin",
                        `size-${size}`
                    )}
                />
            );
            break;
        case "instagram":
            social = (
                <Instagram
                    className={classNames(
                        "svg-component instagram",
                        `size-${size}`
                    )}
                />
            );
            break;
        default:
            social = <></>;
            break;
    }
    return asLink ? (
        <a className="svg-component-link-wrapper" href={asLink} target="_blank">
            {social}
        </a>
    ) : (
        social
    );
};

export default Svg;
