/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactHTML } from "react";
import classNames from "classnames";

import "./style.scss";

export interface ContainerProps {
    fluid?: boolean;
    className?: string;
    children?: any;
    customTag?: keyof ReactHTML;
    padding?: { x?: number | string; y?: number | string };
    style?: React.CSSProperties;
    noPaddingTop?: boolean;
    noPaddingBottom?: boolean;
    noPaddingRight?: boolean;
    noPaddingLeft?: boolean;
}

const Container: React.FC<ContainerProps> = ({
    className,
    children,
    fluid = false,
    customTag,
    padding,
    style,
    noPaddingTop,
    noPaddingBottom,
    noPaddingRight,
    noPaddingLeft,
}) => {
    const classNameContainer = classNames(
        "container",
        className,
        { noPaddingTop, noPaddingBottom, noPaddingRight, noPaddingLeft },
        {
            "container--fluid": fluid,
        },
        padding?.y && `paddingY-${padding?.y}`,
        padding?.x && `paddingX-${padding?.x}`
    );

    const TagAs = customTag ? customTag : "div";

    return (
        <TagAs className={classNameContainer} style={style}>
            {children}
        </TagAs>
    );
};

export default Container;
