/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal from "../Modal";
import { useEffect, useState } from "react";
import FormDownloadEbook from "../FormDownload";

import "./style.scss";

interface SubmitEmailModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
}

const DownloadEbookModal: React.FC<SubmitEmailModalProps> = ({
    isModalOpen,
    setIsModalOpen,
}) => {
    const [isFormSentSuccess, setIsFormSentSuccess] = useState(false);

    useEffect(() => {
        if (!isFormSentSuccess || !isModalOpen) return;

        const closeModalTimeout = setTimeout(() => {
            setIsModalOpen(false);
        }, 5000);

        return () => {
            clearTimeout(closeModalTimeout);
        };
    }, [isFormSentSuccess, isModalOpen, setIsModalOpen]);

    useEffect(() => {
        return () => {
            setIsFormSentSuccess(false);
        };
    }, []);

    return (
        <Modal
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            className="download-ebook"
        >
            <FormDownloadEbook asCard={false} />
        </Modal>
    );
};

export default DownloadEbookModal;
