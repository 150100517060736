/* eslint-disable valid-typeof */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

export enum ScreenSize {
    XS = "xs",
    SM = "sm",
    MD = "md",
    LG = "lg",
    XL = "xl",
    XXL = "xxl",
}

const breakpoints = {
    0: "xs",
    576: "sm",
    768: "md",
    992: "lg",
    1200: "xl",
    1600: "xxl",
};

const useBreakpoint = () => {
    const [breakpoint, setBreakPoint] = useState<string>("");
    const [windowSize, setWindowSize] = useState<{
        width: number;
        height: number;
    }>({
        width: 0,
        height: 0,
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    const isMobile = windowSize.width < 576;
    const isMiniTablet = 576 <= windowSize.width && windowSize.width < 768;
    const isTablet = 768 <= windowSize.width && windowSize.width < 992;
    const isDesktop = 992 <= windowSize.width && windowSize.width < 1200;
    const isLgDesktop = 992 <= windowSize.width && windowSize.width < 1200;
    const isXlDesktop = windowSize.width >= 1600;

    const getBreakPoints = (breakpointsValue: any): string => {
        if (isMobile) {
            return breakpointsValue[0];
        }
        if (isMiniTablet) {
            return breakpointsValue[576];
        }
        if (isTablet) {
            return breakpointsValue[768];
        }
        if (isDesktop) {
            return breakpointsValue[992];
        }
        if (isLgDesktop) {
            return breakpointsValue[1200];
        }
        if (isXlDesktop) {
            return breakpointsValue[1600];
        }

        return "";
    };

    useEffect(() => {
        if (typeof window !== undefined) {
            window.addEventListener("resize", handleResize);

            handleResize();

            setBreakPoint(getBreakPoints(breakpoints));

            return () => window.removeEventListener("resize", handleResize);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize.width]);

    return {
        breakpoint,
        windowSize,
        isMobile,
        isMiniTablet,
        isTablet,
        isDesktop,
        isLgDesktop,
        isXlDesktop,
    };
};

export default useBreakpoint;
