import { createClient } from "contentful";

let devObj: { space: string; accessToken: string; host?: string } = {
    space: import.meta.env.VITE_CF_SPACE_ID || "", // ID of a Compose-compatible space to be used \
    accessToken: import.meta.env.VITE_CF_DELIVERY_ACCESS_TOKEN || "", // delivery API key for the space \
    // host: "preview.contentful.com",
};

if (import.meta.env.VITE_CF_DELIVERY_HOST) {
    devObj = { ...devObj, host: import.meta.env.VITE_CF_DELIVERY_HOST };
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const client = createClient(devObj);

export async function getAppData() {
    const queryContent = {
        content_type: "textContent",
    };

    const { items: contents } = await client.getEntries(queryContent);

    return { contents } || null;
}
