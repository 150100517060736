import React from "react";
import { Card as AntCard, CardProps as AntCardProps } from "antd";

interface CardProps extends AntCardProps {}

const Card: React.FC<CardProps> & { Meta: typeof AntCard.Meta } = ({
    ...props
}) => {
    return <AntCard {...props} />;
};

Card.Meta = AntCard.Meta;

export default Card;
